<template>
  <div class="user-app-list-page w-100">
    <basic-subheader>
      <template v-slot:filters>
        <b-row>
          <div class="p-4">
            <basic-input
              label="Bộ câu hỏi"
              placeholder="Nhập mã hoặc tên bộ câu hỏi"
              name="name"
              class="quize-input"
              :value.sync="filters.searchText"
              :debounce="2000"
              @v-modal:update="searchDebounce"
            ></basic-input>
          </div>
          <div class="p-4">
            <basic-select
              label="Trạng thái"
              placeholder="--- Chọn trạng thái ---"
              name="status"
              :solid="false"
              :allowEmpty="true"
              value-label="text"
              track-by="value"
              class="quize-select"
              :options="statuses"
              :value.sync="filters.status"
              @update:value="searchStatus"
            />
          </div>
        </b-row>
      </template>
      <template v-if="isWritePermission" v-slot:actions>
        <b-button
          class="btn btn-success"
          type="button"
          @click="createQuestions"
        >
          <span class="svg-icon">
            <inline-svg src="/media/svg/icons/Neolex/Basic/plus.svg" />
          </span>
          Thêm mới
        </b-button>
      </template>
    </basic-subheader>
    <Wrapper>
      <template-table
        :column="column"
        :data="data"
        :paging="paging"
        :tableAction="false"
        :selectAction="false"
        :searchAction="false"
        @sortBy="sortRequest"
      >
        <template v-slot:body="{ item }">
          <td>
            {{ item.code }}
          </td>
          <td class="cell-overflow">
            <div class="text-overflow">
              {{ item.name }}
            </div>
          </td>
          <td style="width: 20px">
            <action-dropdown
              :value="item"
              :show_copy="false"
              :show_view="false"
              :show_delete="!showBtnActive(item) && isWritePermission"
              :show_edit="false"
              :boundary="data.length > 2 ? 'scrollParent' : 'window'"
              @delete="showPopupChangeStatus(item.id, 0)"
            >
              <template>
                <b-dropdown-text tag="div" class="navi-item cursor-pointer">
                  <a
                    class="navi-link text-primary"
                    @click.stop="viewQuestionGroup(item)"
                  >
                    <span class="menu-icon svg-icon svg-icon-sm">
                      <inline-svg
                        class="svg-icon"
                        src="/media/svg/icons/Neolex/Basic/eye.svg"
                      />
                    </span>
                    <span class="navi-text text-primary"
                      >Xem chi tiết bộ câu hỏi</span
                    >
                  </a>
                </b-dropdown-text>
                <b-dropdown-divider
                  v-if="isWritePermission"
                ></b-dropdown-divider>
                <template v-if="isWritePermission">
                  <b-dropdown-text tag="div" class="navi-item cursor-pointer">
                    <a
                      class="navi-link text-primary"
                      @click.stop="editQuestionGroup(item)"
                    >
                      <span class="menu-icon svg-icon svg-icon-sm">
                        <inline-svg
                          class="svg-icon"
                          src="/media/svg/icons/Neolex/Basic/edit-2.svg"
                        />
                      </span>
                      <span class="navi-text text-primary"
                        >Chỉnh sửa bộ câu hỏi</span
                      >
                    </a>
                  </b-dropdown-text>
                  <b-dropdown-divider></b-dropdown-divider>
                  <b-dropdown-text
                    v-if="showBtnActive(item)"
                    tag="div"
                    class="navi-item cursor-pointer"
                    @click="showPopupChangeStatus(item.id, 1)"
                  >
                    <a class="navi-link text-primary">
                      <span class="menu-icon svg-icon svg-icon-sm">
                        <inline-svg
                          class="svg-icon"
                          src="/media/svg/icons/Neolex/Basic/power.svg"
                        />
                      </span>
                      <span class="navi-text text-primary">Active</span>
                    </a>
                  </b-dropdown-text>
                </template>
              </template>
            </action-dropdown>
          </td>
          <td class="text-center">
            {{ item.questionCount }}
          </td>
          <td class="text-center">
            {{ formatDate(item.updateDate) }}
          </td>
          <td>
            <div class="status" :class="getStatus(item.status)">
              {{ getStatus(item.status) }}
            </div>
          </td>
        </template>
      </template-table>
      <ModalCreate />
    </Wrapper>
  </div>
</template>
<script>
import { createNamespacedHelpers } from 'vuex';
import { CHANGE_STATUS } from '@/core/services/store/survey.module';
const { mapActions } = createNamespacedHelpers('survey');
export default {
  name: 'SurveyQuizeList',
  components: {
    ModalCreate: () => import('./components/ModalCreate.vue'),
  },
  data() {
    return {
      paging: {
        page: 1,
        pageSize: 10,
        total: 0,
      },
      column: [
        {
          key: 'code',
          label: 'Mã',
          sortable: true,
          style: {
            width: '10%',
          },
        },
        {
          key: 'name',
          label: 'Bộ câu hỏi',
          sortable: true,
        },
        {
          key: 'actionDropdown',
          label: '',
          sortable: false,
          style: {
            width: '20px',
          },
        },
        {
          key: 'questionNumber',
          label: 'Số câu hỏi',
          sortable: false,
          class: 'text-center',
          style: {
            width: '100px',
          },
        },
        {
          key: 'lastUpdate',
          label: 'Cập nhật lần cuối',
          sortable: false,
          class: 'text-center',
          style: {
            width: '170px',
          },
        },
        {
          key: 'status',
          label: 'Trạng thái',
          sortable: false,
          style: {
            width: '5%',
          },
        },
      ],
      data: [],
      sort: {
        by: null,
        order: null,
      },
      filters: {
        searchText: null,
        status: null,
      },
      statuses: [
        {
          text: 'Draft',
          value: '2',
        },
        {
          text: 'Active',
          value: '1',
        },
        {
          text: 'Inactive',
          value: '0',
        },
      ],
    };
  },
  computed: {
    searchParams() {
      return {
        orderBy: this.sort.by ? `${this.sort.by} ${this.sort.order}` : null,
        page: this.paging.page,
        size: this.paging.pageSize,
        searchText: this.filters.searchText || null,
        status: this.filters.status?.value || null,
      };
    },
    isWritePermission() {
      return this.$route.meta.isWritePermission;
    },
  },
  watch: {
    'paging.page'() {
      this.loadData();
    },
    'paging.pageSize'() {
      this.loadData();
    },
  },
  created() {
    this.loadData();
  },
  methods: {
    ...mapActions({ CHANGE_STATUS }),
    async loadData() {
      this.$store.commit('context/setLoading', true);
      try {
        const { meta, data, error } = await this.$api.get(
          '/SurveyQuestionGroup',
          {
            params: this.searchParams,
          },
        );
        if (!meta.success) {
          return this.$toastr.e({
            title: 'Lỗi !',
            msg: error.message,
          });
        }
        this.data = data.items;
        this.paging.total = data.total;
      } catch (error) {
        this.$toastr.e({
          title: 'Lỗi !',
          msg: error.message,
        });
      } finally {
        this.$store.commit('context/setLoading', false);
      }
    },
    sortRequest(val) {
      this.sort.by = val.column;
      this.sort.order = val.order;
      this.loadData();
    },
    editQuestionGroup(item) {
      this.$router.push({
        name: 'survey_quize_edit',
        params: {
          id: item.id,
        },
      });
    },
    viewQuestionGroup(item) {
      this.$router.push({
        name: 'survey_quize_detail',
        params: {
          id: item.id,
        },
      });
    },
    createQuestions() {
      this.$bvModal.show('modal-create-question-group');
    },
    showBtnActive(item) {
      if ([0, 2].includes(item.status)) return true;
      return false;
    },
    formatDate(date) {
      const isValid = this.$moment(date, 'MM/DD/YYYY HH:mm:ss').isValid();
      if (!isValid) return null;
      return this.$moment(date).format('DD/MM/YYYY');
    },
    getStatus(status) {
      switch (status) {
        case 0:
          return 'inactive';
        case 1:
          return 'active';

        default:
          return 'draft';
      }
    },
    searchDebounce(value) {
      this.filters.searchText = value;
      this.resetPaging();
    },
    resetPaging() {
      this.paging.page = 1;
      this.loadData();
    },
    searchStatus(value) {
      this.filters.status = value
        ? this.statuses.find((el) => el.value === value)
        : null;
      this.resetPaging();
    },
    showPopupChangeStatus(id, state) {
      this.$swal({
        title: 'Xác nhận',
        text: `Bạn có chắc chắn muốn chuyển trạng thái thành ${
          state ? 'active' : 'inactive'
        } không?`,
        icon: 'warning',
        buttons: {
          cancel: {
            text: 'Huỷ bỏ',
            value: false,
            visible: true,
            className: '',
            closeModal: true,
          },
          confirm: {
            text: 'Xác nhận',
            value: true,
            visible: true,
            className: '',
            closeModal: true,
          },
        },
      }).then(
        function (result) {
          if (result) {
            // inactive all
            this.changeStatus(id, state);
          }
        }.bind(this),
      );
    },
    async changeStatus(id, state) {
      this.$store.commit('context/setLoading', true);
      const params = {
        id,
        state,
      };
      const { error } = await this.CHANGE_STATUS(params);
      if (!error) {
        this.$toastr.s({
          title: 'Thành công !',
          msg: 'Cập nhật thành công',
        });
        this.loadData();
      } else {
        this.$toastr.s({
          title: 'Lỗi !',
          msg: error,
        });
      }
      this.$store.commit('context/setLoading', false);
    },
  },
};
</script>
<style lang="scss" scoped>
.status {
  padding: 6px 24px;
  border-radius: 20px;
  color: #fff;
  text-transform: capitalize;
  width: 96px;
  text-align: center;

  &.active {
    background: #21a567;
  }

  &.inactive {
    background: #ff5756;
  }

  &.draft {
    background: #888c9f;
  }
}

.text-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.cell-overflow {
  max-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.quize-input {
  min-width: 220px;
}
</style>
<style lang="scss">
.quize-select {
  .multiselect {
    min-width: 220px;
  }
}
</style>
